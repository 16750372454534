import { $ } from '../lib/patched-jquery';
import 'slick-carousel';

(() => {
    if (typeof $ !== 'function') {
        console.error('jQuery is not loaded');
        return;
    }

    if (typeof $.fn['slick'] !== 'function') {
        console.error('Slick is not loaded into jquery');
        return;
    }

    // configure sliders
    $(window).on('load', function () {
        $("[data-section-handler='TestimonialsSection'] .testimonial-slider").slick({
            dots: false,
            arrows: true,
            infinite: false,
            speed: 1000,
            slidesToShow: 3,
            slidesToScroll: 3,
            centerMode: false,
            prevArrow: '.testimonial-navigator__prev',
            nextArrow: '.testimonial-navigator__next',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    });
})();